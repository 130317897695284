import styled from '@emotion/styled';
import { ObjectId } from '@racemap/sdk/schema/base';
import { AtomicEvents, VisibilityStates } from '@racemap/utilities/consts/events';
import {
  isAtomicEvent,
  isChildEvent,
  isContestGroupEvent,
} from '@racemap/utilities/functions/utils';
import type { RacemapEventCommon } from '@racemap/utilities/types/events';
import { Card, Col, Flex, Row, Space, Typography } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useBrands, useCurrentEvent, useIsAdmin } from '../lib/customHooks';
import { useStore } from '../store/reducers';
import AddEditors from './AddEditors';
import { AddEventToCustomBrands } from './AddEventToCustomBrands';
import { ImageDropView } from './BasicComponents/ImageDropView';
import { PopoverHint } from './BasicComponents/PopoverHint';
import { ChangeCreator } from './ChangeCreator';
import { LoadsInfo } from './EventEditor/AnalyticsTab/LoadsInfo';
import { ArchiveButton } from './EventEditor/ArchiveButton';
import FieldGroup from './FieldGroup';
import { BlurFormInput } from './FormComponents';
import { GroupEventList } from './GroupEventList';
import RemoveContestGroup from './RemoveContestGroup';
import SearchEvent from './SearchEvent';
import VisibilitySection from './VisibilitySection';
import { findLastDateAndFirstDate } from './utils/preprocess';

const { Title, Text } = Typography;

export function ContestGroupEditor() {
  const contestGroup = useCurrentEvent();
  const isAdmin = useIsAdmin();
  const { brands } = useBrands();
  const { updateEvent, loadEvents, addContests } = useStore((s) => s.events.actions);
  const groupEvents = useStore((s) =>
    contestGroup != null
      ? Array.from(s.events.items.values()).filter(
          (e) => isChildEvent(e) && e.parent === contestGroup.id,
        )
      : [],
  );

  useEffect(() => {
    (async () => {
      await loadGroupEvents();
    })();
  }, []);

  if (contestGroup == null || !isContestGroupEvent(contestGroup)) return <></>;

  const slug = contestGroup.slug;
  const dateFormat = 'HH:mm dddd, D.M.Y';

  const onChangeContestGroupField = async (
    key: Parameters<typeof updateEvent>[1][0]['key'],
    newValue: Parameters<typeof updateEvent>[1][0]['newValue'],
  ) => {
    if (key === 'childEvents') return;
    updateEvent(contestGroup.id, [{ key, newValue }]);
  };

  const loadGroupEvents = async () => {
    await loadEvents({ findByParentId: contestGroup.id });
  };

  const getStartTimeAndEndTimeExtends = () => {
    const [firstDate, lastDate] = findLastDateAndFirstDate(contestGroup?.childEvents);
    if (firstDate == null || lastDate == null) return ['', ''];
    return [moment(lastDate).format(dateFormat), moment(firstDate).format(dateFormat)];
  };

  const getIgnoreList = (): Array<string> => {
    return groupEvents.map((e) => e.id);
  };

  const handleEventFound = async (newChild: RacemapEventCommon | null) => {
    if (newChild == null || contestGroup == null) return;
    if (!isAtomicEvent(newChild)) return;

    await addContests([new ObjectId(newChild.id)], new ObjectId(contestGroup.id));
  };

  if (contestGroup == null) return <div />;
  const [lastDate, firstDate] = getStartTimeAndEndTimeExtends();
  const ignoreList = getIgnoreList();

  return (
    <Container>
      <form>
        <Row>
          <Col lg={24}>
            <Space>
              <Title>Edit contest group</Title>
              <Text type="secondary">Stack multiple contests in one</Text>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            {slug != null && (
              <Space size={5}>
                Map:&nbsp;
                <a href={`/player/${slug}`} target="_blank" rel="noopener noreferrer">
                  {`${window.location.protocol}//${window.location.host}/player/${slug}`}
                </a>
                <PopoverHint placement="bottom">
                  On desktop applications, the group URL preselects specific single events,
                  automatically. During the event, ALL RUNNING single events in the group are
                  selected, automatically. After the event, the 1st single event in group settings
                  is selected, automatically.
                </PopoverHint>
              </Space>
            )}
            <p>
              Stack multiple Tracking Maps To summarize specific events in one map, e.g. for staged
              events and for events with several contests (marathon, half marathon etc.). This way
              spectators access multiple tracking maps under one link.
            </p>
          </Col>
        </Row>
        {}
        <h4>Basic Settings</h4>
        <Card>
          <Row gutter={16}>
            <Col lg={8}>
              <BlurFormInput
                id="contestGroupName"
                type="text"
                label="Name"
                required
                placeholder="Enter the name of the ContestGroup"
                value={contestGroup.name}
                onChange={(newValue: string) => onChangeContestGroupField('name', newValue)}
              />

              <BlurFormInput
                id="contestGroupLocation"
                type="text"
                label="Location"
                required
                placeholder="Enter the location"
                value={contestGroup.location}
                onChange={(newValue: string) => onChangeContestGroupField('location', newValue)}
              />
            </Col>
            <Col lg={10}>
              <Title level={5}>Visibility</Title>
              <VisibilitySection
                value={contestGroup.visibility}
                onChange={(newValue) => onChangeContestGroupField('visibility', newValue)}
                authorized
                disabled={groupEvents.length === 0}
                possibleStates={[VisibilityStates.LISTED, VisibilityStates.UNLISTED]}
                target="contestGroup"
              />
            </Col>
            <Col lg={4} offset={2}>
              <Flex justify="flex-end">
                <Flex vertical>
                  <Title level={5}>
                    Logo for Event{' '}
                    <PopoverHint title="Logo for Event">
                      Racemap shows square logos as jpg or png files. You drag and drop the logo
                      into the box. You can always update the logo.
                    </PopoverHint>
                  </Title>
                  <ImageDropView
                    value={contestGroup.images}
                    onChange={(images) => onChangeContestGroupField('images', images)}
                  />
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Card>
        {contestGroup.editorIds != null && (
          <>
            <h4 style={{ marginTop: '20px' }}>Info</h4>
            <Card>
              <Row gutter={16}>
                <Col lg={10}>
                  <AddEditors
                    value={contestGroup.editorIds.map((id) => new ObjectId(id))}
                    onChange={(newEditorIds) => {
                      onChangeContestGroupField(
                        'editorIds',
                        newEditorIds.map((id) => id.toHexString()),
                      );
                    }}
                  />
                </Col>

                {isAdmin && (
                  <>
                    <Col lg={7}>
                      <Flex vertical>
                        <Text strong>Creator</Text>

                        <ChangeCreator
                          value={new ObjectId(contestGroup.creatorId)}
                          onChange={(newCreatorId) => {
                            onChangeContestGroupField('creatorId', newCreatorId.toHexString());
                          }}
                        />
                      </Flex>
                    </Col>
                    <Col lg={7}>
                      <FieldGroup
                        id="contestGroupSlug"
                        label="Group Slug"
                        required
                        placeholder="Enter the slug of the ContestGroup"
                        value={contestGroup.slug}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          onChangeContestGroupField('slug', event.target.value)
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          </>
        )}
        <h4 style={{ marginTop: '20px' }}>Contests</h4>
        <Card>
          <Row>
            <Col lg={24} className="mb-2">
              <SearchEvent
                onSelect={handleEventFound}
                filter="can-edit"
                show={['hidden', 'regulareventsonly']}
                eventFilter={(e) => {
                  return AtomicEvents.includes(e.type);
                }}
                ignoreList={ignoreList}
                emptyAfterChoose={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <GroupEventList />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }} gutter={16}>
            <Col lg={12}>
              <FieldGroup id="contestGroupStartTime" label="Starttime" disabled value={firstDate} />
            </Col>

            <Col lg={12}>
              <FieldGroup id="contestGroupEndTime" label="Endtime" disabled value={lastDate} />
            </Col>
          </Row>
        </Card>
        <h4 style={{ marginTop: '20px' }}>Loads</h4>
        <Card>
          <LoadsInfo />
        </Card>
        <Row>
          <Col lg={24}>
            <h4 style={{ marginTop: '20px' }}>Danger Zone</h4>
            <Card>
              <Row>
                <Col lg={24}>
                  <Space>
                    {isAdmin && <RemoveContestGroup />}
                    <ArchiveButton />
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </form>
      {!!brands?.length && (
        <section>
          <h4 style={{ marginTop: '20px' }}>Maintenance</h4>
          <Card>
            <Row>
              <Col md={12}>
                <AddEventToCustomBrands event={contestGroup} />
              </Col>
            </Row>
          </Card>
        </section>
      )}
    </Container>
  );
}

const Container = styled(Flex)`
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
`;
