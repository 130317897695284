import { DateSchema, type ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import type { BillableItem, EventDayBasedBillableItem } from '@racemap/sdk/schema/billing';
import { type BillableItemTypes, UserSchema } from '@racemap/sdk/schema/user';
import type { Document, Types } from 'mongoose';
import { z } from 'zod';
import type { PRICING_VERSIONS } from '../consts/billing';
import type { SportTypes } from '../consts/eventTypes';
import type {
  AddOns,
  AppScreenKeys,
  AuthorizationStates,
  DeviceClasses,
  EventTypes,
  FlagContent,
  LeaderboardHeaderContent,
  LeaderboardType,
  MapType,
  ModulesStates,
  PinToShadowTrackMode,
  PlayerInfoSheetTabKeys,
  RegistrationType,
  TimeMode,
  TimingSystems,
  UnitType,
  VisibilityStates,
} from '../consts/events';
import type { TRACKER_TYPES } from '../consts/trackers';
import type { TagsCollection } from '../functions/timing';
import type { EventLoadTypes } from './events';
import type { EventTrackObject, GeoObjects } from './geos';
import type { TrackerObject } from './trackers';
import type { Editor, UserDocument } from './users';
import type { ID } from './utils';

export { EventLoadTypes } from '@racemap/sdk/schema/events';

type PaddingOptions = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

type ViewState = {
  /** Longitude at map center */
  longitude: number;
  /** Latitude at map center */
  latitude: number;
  /** Map zoom level */
  zoom: number;
  /** Map rotation bearing in degrees counter-clockwise from north */
  bearing: number;
  /** Map angle in degrees at which the camera is looking at the ground */
  pitch: number;
  /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
  padding: PaddingOptions;
};

export interface Images extends Record<string, string | undefined> {
  fileName: string;
  original: string;
  app?: string;
  large?: string;
  medium?: string;
  small?: string;
  property?: string;
  icon?: string;
}

export type MetaData = {
  sponsor:
    | {
        url: string;
        title: string;
        logo: Images;
      }
    | null
    | undefined;
};

export interface LeaderboardOptions {
  externalLeaderboardUrl: string;
  type: LeaderboardType;
}

export type PredictionParams = {
  // Parameter influencing the mapping
  // A reader location must be within this distance to the track
  distanceFromTrack: number; // meters
  // Parameter influencing the interpolation allgorithm
  // the max speed in m/s that is used to correct faulty predictions in live mode
  maxCompensationSpeed: number; // meters / second
  // minimum distance before we accept a new valid ping position -> bigger number decreases speed measuring errors
  minDistance: number; // meters
  // all Pings below this value will be ignored
  rssiThreshold: number; // dBm
  // At this point in future the simulated starter should meet the measured starter
  futureTrackScaleValue: number;
  // We use this distance in meters to measure the speed of a starter for approximation
  lookBackDistance: number; // meters
  // we use this time interval to look into the future. In live mode we let the starters run lookForwardTimeInMs into the future. This should compensate for latency issues rising when pushing data through our system until it arrives in the player
  lookForwardTime: number; // seconds
  // when the live prediction is to far away (timewise) from the last measured position we jump to the last measured position
  // we do a jump forward or backwards if the time difference is bigger than jumpingDotThresholdInMs
  jumpingDotThreshold: number; // seconds
};

export type OptionModules = {
  predictive: {
    state: undefined;
    enabled: boolean;
    timingsystem: TimingSystems;
    params: PredictionParams;
  };
  sponsor: {
    state: ModulesStates;
    logo: Images | null;
    url: string;
    title: string;
  };
  keys: { enabled: boolean; state: undefined };
  timing: {
    leaderboard: LeaderboardOptions;
    state: ModulesStates;
    predefinedTrack: boolean;
    distance: number;
    duration: number;
    target: string;
    teamTarget: TeamTarget;
    liveRanking: boolean;
    visibleElements: VirtualRaceVisibleElements;
  };
  dataFeed: {
    state: ModulesStates;
    geofenceRange: number;
  };
  netReplay: {
    state: undefined;
    enabled: boolean;
    splitId: string | null;
    sliderDuration: number;
  };
  projection: { enabled: boolean; state: undefined };
  brandedAppIds: Array<ID>;
  registration: {
    type: RegistrationType;
    rrRegistrationKey: string;
    externalRegistrationUrl: string;
    rrRegistrationFormName: string;
  };
  monitor: {
    state: ModulesStates;
    alertTargetMails: Array<{ email: string; lang: 'en' }>;
  };
};

export type AlertOptions = {
  noMovementDuration: number;
};

export type PaymentInfo = {
  invariants: {
    pricingVersion: PRICING_VERSIONS;
    isReseller: boolean;
  };
};

export type Geos = {
  features?: GeoObjects;
  /**
   * @deprecated: old hash value that lead to entry in S3
   */
  geojsonHash?: string;
  /**
   * @deprecated: old hash value that lead to entry in S3
   */
  shadowgeojsonHash?: string;
  shadowtrackId: string | null;
};

export type RRCustomerIds = Array<string>;
export type RRContests = Array<string>;
export type RREventId = string;
export enum RRImportMode {
  EVENT_ID = 'EVENT_ID',
  CUSTOM_URL = 'CUSTOM_URL',
}
export enum RRLeaderboardType {
  RESULTS = 'results',
  LIVE = 'live',
  REGISTRATION = 'registration',
}

export type RacemapKey = {
  used: boolean;
  key: string;
  id: ID;
  starterId: ID;
};

export interface TeamTarget {
  enabled: boolean;
  distance: number;
  description: string;
}

export interface VirtualRaceVisibleElements {
  difference: boolean;
  activeDuration: boolean;
  restDuration: boolean;
  liveState: boolean;
  currentProgress: boolean;
  finish: boolean;
  stages: boolean;
  rank: boolean;
  eventTimes: boolean;
  mapLink: boolean;
  distanceInHeader: boolean;
  participateAfterEvent: boolean;
  headerContent: Array<LeaderboardHeaderContent>;
}

export interface ViewportOptions {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
}

export interface ElevationChartOptions {
  hidden: boolean;
  min: number | null;
  max: number | null;
}

export interface InfoTabs {
  openByDefault: boolean;
  defaultTab: PlayerInfoSheetTabKeys;
  disabledTabs: Array<PlayerInfoSheetTabKeys>;
}

export interface PlayerOptions {
  stepWidth: number;
  markerTimeout: number;
  breakTimeout: number;
  replaySpeedup: number;
  liveDelay: number;
  pollingInterval: number;
  metaData: MetaData | null;
  mapType: MapType;
  unitType: UnitType;
  flagContent: FlagContent;
  showAllFlags: boolean;
  showDistanceMarkers: boolean;
  infoTabs: InfoTabs;
  pinToShadowTrack: PinToShadowTrackMode;
  version: string;
  viewport: null | ViewState;
  filterTagKeys: null | Array<string>;
  elevationChartOptions: ElevationChartOptions;
  listOpenByDefault: boolean;
  mappingDistance: number;
  timeMode: TimeMode;
  tailTimeout: number;
  visibleStarterGroups: Array<string>;
}

export interface PlayerOptionsObject extends PlayerOptions {
  defaultTagFilters: Record<string, Array<{ value: string }>>;
}

export interface PlayerOptionsDocument extends PlayerOptions {
  defaultTagFilters: TagsCollection;
}

export interface IntegrationObject {
  raceresult: {
    isActive: boolean;
    eventId: RREventId;
    contests: RRContests;
    customerIds: RRCustomerIds;
    starterListUrl: string;
    importMode: RRImportMode;
    lastImportAt: string | null;
    leaderboardType: RRLeaderboardType;
  };
  feibot: {
    eventId: string | null;
  };
  genericStarterImport: {
    isActive: boolean;
    sources: Array<{ sourceURL: string }>;
    lastImportAt: string | null;
  };
  facebook?: {
    pageIds: Array<string>;
  };
}

export interface AppOptions {
  screens: {
    defaultScreen: AppScreenKeys;
    disabledScreens: Array<AppScreenKeys>;
  };
}

export type StageGroupHint = {
  name: string;
  id: ID;
};

export type RacemapEventCommon = {
  id: ID;
  creatorId: ID;
  createdAt: string;
  updaterId: ID;
  updatedAt: string;
  copyOf?: string;
  /**
   * @deprecated - should replace by date of log entry
   */
  paidAt: string;
  /**
   * @deprecated - only for backward compatibility
   */
  activatedUntil: string;

  name: string;
  description: string;
  slug: string;
  type: EventTypes;
  tags: Array<string>;
  eventType: SportTypes | null;
  maxSpeed: number;
  minSpeed: number;
  startTime: string;
  endTime: string;
  location: string;
  isRepeating: boolean;
  defaultSpeed: number;

  me?: Partial<RacemapStarter>;
  index?: number;
  startTimeStamp?: number;
  endTimeStamp?: number;
  eventDescription: string;
  eventWebsite: string;
  eventContactMail: string;

  starterIds?: Array<ID>;
  starters?: RacemapStarters;
  images: Images | null;
  editorIds: Array<ID>;
  editors: Array<Editor>;
  creator: Editor;
  visibility: VisibilityStates;
  passcode: string;
  isSticky: boolean;
  authorization: AuthorizationStates;
  parent: ID | null;

  modules: OptionModules;

  payments: PaymentInfo;
  geo: Geos;

  key: string;
  keys: Array<RacemapKey>;

  playerOptions: PlayerOptionsObject;
  appOptions: AppOptions;

  integrations: IntegrationObject;
  startersCount?: number;
  bookingData?: unknown;
  keyRequired?: boolean;
  isHidden?: boolean;
  isStage?: boolean;
  stageGroup?: StageGroupHint;
  startTimeOrderingKey?: string;
  alertOptions: AlertOptions;
};

export type RacemapRegularEvent<T = RacemapEventCommon> = T & {
  type: EventTypes.REGULAR;
};

export type RacemapStageGroup<T = RacemapEventCommon> = T & {
  type: EventTypes.STAGE_GROUP;
  childEvents?: Array<RacemapStageEvent>;
};

export type RacemapStageEvent<T = RacemapEventCommon> = T & {
  type: EventTypes.STAGE;
  parent: ID;
  parentEvent?: Partial<RacemapStageGroup>;
};

export type RacemapContestGroup<T = RacemapEventCommon> = T & {
  type: EventTypes.CONTEST_GROUP;
  childEvents?: Array<RacemapContestEvent>;
};

export type RacemapContestEvent<T = RacemapEventCommon> = T & {
  type: EventTypes.CONTEST;
  parent: ID;
  parentEvent?: Partial<RacemapContestGroup>;
};

export type AtomicEvent<T = RacemapEventCommon> =
  | RacemapRegularEvent<T>
  | RacemapStageEvent<T>
  | RacemapContestEvent<T>;
export type GroupEvent<T = RacemapEventCommon> = RacemapStageGroup<T> | RacemapContestGroup<T>;
export type ChildEvent<T = RacemapEventCommon> = RacemapStageEvent<T> | RacemapContestEvent<T>;

export type RacemapEvent = RacemapEventCommon;
export interface AddedUser {
  id: UserDocument['id'];
  name: UserDocument['name'];
  isReseller: UserDocument['isReseller'];
  raceResultCustomerId: UserDocument['integrations']['raceResultCustomerId'];
}

export interface EventDocument extends Document<ObjectId> {
  playerOptions: PlayerOptionsDocument;
  appOptions: AppOptions;
  name: string;
  type: EventTypes;
  copyOf: Types.ObjectId | null;
  slug?: string;
  bookingData?: unknown;
  location: string;
  defaultSpeed: number;
  maxSpeed: number;
  minSpeed: number;
  isSticky: boolean;
  startersCount?: number;
  editorIds: Array<ObjectId>;
  creatorId: ObjectId;
  updaterId: ObjectId;
  eventType: SportTypes | null;
  startTime: Date;
  endTime: Date;
  isRepeating: boolean;
  // @deprecated - only for backward compatibility
  paidAt: Date;
  // @deprecated - only for backward compatibility
  activatedUntil: Date;
  startTimeOrderingKey: Date;
  createdAt: Date;
  updatedAt: Date;
  eventDescription?: string;
  eventWebsite: string;
  eventContactMail: string;
  geo: {
    shadowtrackId: Types.ObjectId | null;
    // @deprecated - only for backward compatibility
    geojsonHash?: string;
    shadowgeojsonHash?: string;
    geosUpdatedAt: Date;
  };
  images: Images | null;
  passcode: string;
  keyRequired: boolean;
  visibility: string;
  isHidden: boolean;
  starterIds: Array<Types.ObjectId>;
  authorization: AuthorizationStates;
  modules: OptionModules;
  index: number | null;
  parent: Types.ObjectId | null;
  eventIds?: Array<ID>;
  parentEvent?: Partial<EventDocument>;
  payments: {
    invariants: {
      isReseller: boolean;
      pricingVersion: PRICING_VERSIONS;
    };
  };
  integrations: {
    raceresult: {
      eventId: string;
      contests: Array<string>;
      customerIds: Array<string>; // holds a list of customerIds, for this ids we will try find related hardware
      starterListUrl: string;
      importMode: RRImportMode;
      isActive: boolean;
      leaderboardType: RRLeaderboardType;
    };
    feibot: {
      eventId: string | null;
    };
    genericStarterImport: {
      sources: Array<{ sourceURL: string }>;
      isActive: boolean;
    };
  };
  alertOptions: AlertOptions;
  me?: RacemapStarter;
  canEdit: (user?: UserDocument | null) => Promise<boolean>;
  canRead: (user?: UserDocument | null) => Promise<boolean>;
  getStartersWithAppId: (appId: string) => Promise<Array<StarterDocument>>;
  getChildEvents: () => Promise<Array<ChildEventDocument>>;
  getParentEvent: () => Promise<GroupEventDocument>;

  // properties just added on delivery by the api
  markerTimeout?: number;
  breakTimeout?: number;
  hasShadowTrack?: boolean;
  virtualDistance?: number | null;
  renewedAt?: Date;
}

export type RacemapRegularEventDocument = EventDocument & {
  type: EventTypes.REGULAR;
};

export type RacemapStageGroupDocument = EventDocument & {
  type: EventTypes.STAGE_GROUP;
  childEvents?: Array<RacemapStageEvent>;
};

export type RacemapStageEventDocument = EventDocument & {
  type: EventTypes.STAGE;
  parent: ID;
  parentEvent?: Partial<RacemapStageGroup>;
};

export type RacemapContestGroupDocument = EventDocument & {
  type: EventTypes.CONTEST_GROUP;
  childEvents?: Array<RacemapContestEvent>;
};

export type RacemapContestEventDocument = EventDocument & {
  type: EventTypes.CONTEST;
  parent: ID;
  parentEvent?: Partial<RacemapContestGroup>;
};

export type AtomicEventDoicment =
  | RacemapRegularEventDocument
  | RacemapStageEventDocument
  | RacemapContestEventDocument;
export type GroupEventDocument = RacemapStageGroupDocument | RacemapContestGroupDocument;
export type ChildEventDocument = RacemapStageEventDocument | RacemapContestEventDocument;

export type TPOMEvent = {
  id: ID;
  name: string;
};

export const EventSnapshotDocumentSchema = z.object({
  _id: ObjectIdSchema,
  eventId: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  event: z.object({}),
  canRead: z.function().args(UserSchema.nullish()).returns(z.promise(z.boolean())),
  canEdit: z.function().args(UserSchema.nullish()).returns(z.promise(z.boolean())),
});

export type EventSnapshotDocument = z.infer<typeof EventSnapshotDocumentSchema> &
  Document<Types.ObjectId> & {
    event: EventDocument;
    canRead: (user?: UserDocument | null) => Promise<boolean>;
    canEdit: (user?: UserDocument | null) => Promise<boolean>;
  };

export type RacemapTrack = {
  id: ID;
};

export interface RacemapTrackDocument extends Document {
  appId: string;
  createdAt: Date;
  updatedAt: Date;
  points?: Array<GeolocationCoordinates>;
}

export type RacemapStarters = Map<ID, RacemapStarter>;

export interface BasicRacemapStarter {
  name?: string;
  startNumber?: string;
  deviceClass?: DeviceClasses | null;
  deviceType?: TRACKER_TYPES | string | null;
}

export type RacemapStarterPrototype = Required<BasicRacemapStarter> & {
  deviceId?: string | null;
  appId?: string;
};

export type Tags = Record<string, string> & {
  age?: string;
  sex?: string;
  club?: string;
  nationality?: string;
  status?: string;
  contest?: string;
};

export interface RacemapStarter extends BasicRacemapStarter {
  id: ID;
  markerColor?: string;
  eventId: ID;
  keyUsed?: boolean;
  trackId?: string;
  appId: string | null;
  startTime?: string;
  endTime?: string;
  startTimeStamp?: number;
  endTimeStamp?: number;
  times: { start?: string; end?: string } & Record<string, string | undefined>;
  manualFinishDuration?: number;
  createdAt?: string;
  updatedAt?: string;
  key?: string;
  integrations?: {
    importId?: string;
    raceresultKey?: string;
    genericImportKey?: string;
    messagingToken?: null | string;
  };
  deviceName?: TrackerObject['trackerName'];
  deviceState?: TrackerObject['state'];
  deviceActivation?: TrackerObject['activation'];
  deviceId: string | null;
  tags: Tags;
  gpxUrl?: string;
}

export type Device = {
  id?: ID;
  class: string | null;
  type: string | null;
};

export type GenericImportStarterRecord = Record<
  string,
  string | Record<string, string | null> | Array<Device>
> & {
  importId: string; // the id the starter has in its source database (customer)
  eventId?: string;
  key?: string; // the key used by the starter allow its participation
  name?: string; // the name of the starter
  imei?: string; // when it should be a GPS tracker (has to be in IMEI format)
  appId?: string; // when its an app
  times?: { start?: string; end?: string } & Record<string, string>;
  devices?: Array<Device>;
  /**
   * @deprecated Use lastTime instead.
   */
  endTime?: string;
  /**
   * @deprecated Use firstTime instead.
   */
  startTime?: string;
  firstTime?: string; // start of the track
  lastTime?: string; // end of the track
  startNumber?: string; // its given start number
  markerColor?: string; // the color of the starter in the map
  tags?: Record<string, string | null>; // tags to filter the starters
  'device.id'?: string; // alternative way to set device id
  'device.type'?: string; // alternative way to set device type
  'device.class'?: string; // alternative way to set device class
};

export interface StarterDocument extends Document {
  eventId: ObjectId;
  createdAt: Date;
  updatedAt: Date;
  name?: string;
  startNumber?: string;
  trackId: ObjectId;
  key?: string;
  keyUsed: boolean;
  appId: string | null;
  deviceClass: string | null;
  deviceType: string | null;
  startTime?: Date;
  endTime?: Date;
  deviceId: ObjectId | null;
  tags: Tags;
  manualFinishDuration: number | null;
  markerColor: string;
  integrations: {
    importId: string;
    raceresultKey: string;
    genericImportKey: string;
    messagingToken: string | null;
  };
  times: { start?: Date; end?: Date } & Record<string, Date | undefined>;
  canEdit: (user: UserDocument | null | undefined) => Promise<boolean>;
  canRead: (user: UserDocument | null | undefined) => Promise<boolean>;
  hasValidDeviceId: (user: UserDocument | null) => boolean;
}

export type StarterKey = {
  key: string;
  eventId: ID;
  createdAt: string;
  updatedAt: string;
  used: boolean;
  usedAt: string;
  starterId: ID;
};

export interface StarterKeyDocument extends Document {
  key: string;
  used: boolean;
  usedAt: string;
  eventId: ObjectId;
  starterId: ObjectId;
  createdAt: string;
  updatedAt: string;
}

export type Shadowtrack = EventTrackObject;

export type TokenObject = {
  userId: ID;
  token: string;
};

export type Load = {
  eventId: ID;
  type: EventLoadTypes;
  time: string;
  count: number;
};

export interface LoadDocument extends Document {
  eventId: ObjectId;
  type: EventLoadTypes;
  time: Date;
  count: number;
}

export type KeyData = {
  startNumber: string;
  name: string;
  eventId: string;
  eventName?: string;
  starterId?: string;
  keyUsed: boolean;
};

export type StripeInvoiceInfo =
  | {
      invoiceId: string;
      status: 'OPEN';
      invoiceUrl: string;
    }
  | { invoiceId: string; status: 'PAID' }
  | { status: 'NONE' };

/**
 * @deprecated This interface is deprecated
 */
export interface BillableItemObject {
  eventId: Array<string>;
  userId: string;
  itemId?: string;
  type: BillableItemTypes;
  timeKey?: string;
  quantity: number;
  time: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * @deprecated This interface is deprecated
 */
export interface BillableEventObject extends BillableItemObject {
  activatedAddons: Array<AddOns>;
  type: BillableItemTypes.EVENT_CYCLE;
  timeKey: string;
}

type EventFreeBillableItemTypes =
  | BillableItemTypes.SMS
  | BillableItemTypes.SIM_CARD
  | BillableItemTypes.APP
  | BillableItemTypes.TRACKER_MANAGEMENT;

/**
 * @deprecated This interface is deprecated
 */
export interface BillableEventBasedItem extends BillableItem {
  eventId: ObjectId;
  userId: ObjectId;
  type: Exclude<BillableItemTypes, EventFreeBillableItemTypes | BillableItemTypes.KEY>;
}
/**
 * @deprecated This interface is deprecated
 */
export type BillableEventBasedItemDocument = Document & BillableEventBasedItem;

/**
 * @deprecated This interface is deprecated
 */
export interface BillableEventFreeItem extends BillableItem {
  eventId: null;
  userId: ObjectId;
  type: EventFreeBillableItemTypes;
}
/**
 * @deprecated This interface is deprecated
 */
export type BillableEventFreeItemDocument = Document & BillableEventFreeItem;

/**
 * @deprecated This interface is deprecated
 */
export interface BillableKeyItem extends BillableItem {
  eventId: ObjectId;
  userId: null;
  type: BillableItemTypes.KEY;
}
/**
 * @deprecated This interface is deprecated
 */
export type BillableKeyItemDocument = Document & BillableKeyItem;
/**
 * @deprecated This interface is deprecated
 */
export type EventDayBasedBillableItemDocument = Document & EventDayBasedBillableItem;

/**
 * @deprecated This interface is deprecated
 */
export type BillableItemDocument =
  | BillableEventBasedItemDocument
  | BillableEventFreeItemDocument
  | BillableKeyItemDocument
  | EventDayBasedBillableItemDocument;

export interface BillableDeviceDocument extends Document {
  eventIds: Array<Types.ObjectId>;
  ownerId: ObjectId;
  trackId: ObjectId;
  time: Date;
  timeKey: string;
  createdAt: Date;
  updatedAt: Date;
  deviceClass: 'gps_device' | 'transponder' | null;
}

/**
 * @deprecated This interface is deprecated
 */
export interface BillableEventDocument extends Document {
  eventId: Types.ObjectId;
  ownerId: Types.ObjectId;
  activatedAddons: Array<AddOns>;
  time: Date;
  timeKey: string;
  createdAt: Date;
  updatedAt: Date;
}

export type ImportStarterCheckResult = {
  importId: string; // import id of this starter relates to the import source document
  name: string; // name of the starter
  errors: Array<string>; // errors preventing this starter from import
};

export type GenericImportCheckResult = {
  errors: Array<string>;
  failures: Array<ImportStarterCheckResult>;
  validCount: number;
};

export type PlayerHTMLEventInfo = {
  name: string;
  playerUrl: string;
  previewUrl: string;
  authorized: boolean;
  eventId: string;
};

export type SEO = {
  keywords: string;
  description: string;
};

export type PlayerHTMLPlayerOptions = {
  type: EventTypes;
  cdnHost: string | undefined;
  app: string | boolean;
  metaData: RacemapEvent['playerOptions']['metaData'];
};

export type StartTimesState = {
  description: string;
  affectedStarters: number;
  gunStartTime: string;
  isGunStart: boolean;
  numberOfUnknownStartTimes: number;
  numberOfKnownStartTimes: number;
};
