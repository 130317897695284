import { z } from 'zod';
import { BufferSchema, DateSchema, ObjectIdSchema } from './base';
import { SimCardSchema } from './simCard';
export enum TRACKER_TYPES {
  TK5000 = 'TK5000',
  Coban = 'Coban',
  TK102 = 'TK102',
  CatTrack = 'CatTrack',
  LK1062G = 'LK106-2G',
  LK1063G = 'LK106-3G',
  MiniFinderPicoOrAtto = 'MiniFinderPicoOrAtto',
  GL300 = 'GL300',
  GL300W = 'GL300W',
  GL300LTEME = 'GL300LTE-ME',
  GL300LTEMA = 'GL300LTE-MA',
  GL300LTEMG = 'GL300LTE-MG',
  GL310LTEMG = 'GL310LTE-MG',
  GL320LTEMG = 'GL320LTE-MG',
  LiveRank = 'LiveRank',
  PrimeII = 'PrimeII',
  Mictrack = 'Mictrack',
  Teltonika = 'Teltonika',
  RMSportTrackerMk0 = 'RMSportTracker-Mk0',
  MT90 = 'MT90',
  M20 = 'M20',
  RacemapTracker = 'racemapTracker',
  RRTrackbox = 'RRTrackbox',
  RRDecoder = 'RRDecoder',
  RRUbidium = 'RRUbidium',
}

export enum TrackerActivationType {
  NONE = 'NONE',
  ACTIVATED = 'ACTIVATED',
  RACEMAPTRACKER = 'RACEMAPTRACKER',
  ARCHIVED = 'ARCHIVED',
}

export enum LogisticsState {
  inStock = 'inStock',
  outOfStock = 'outOfStock',
}

export enum USER_ROLE {
  OWNER = 'owner',
  EDITOR = 'editor',
  BORROWER = 'borrower',
}

export enum RadioType {
  NONE = 'NONE',
  GSM = 'GSM',
  LTE = 'LTE',
  UMTS = 'UMTS',
}

export enum FIXED_REPORT_MODE {
  NONE = 'NONE',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  DISTANCE = 'DISTANCE',
  MILEAGE = 'MILEAGE',
  OPTIMUM = 'OPTIMUM',
  TIME_OR_MILEAGE = 'TIME_OR_MILEAGE',
}

export enum NON_MOVE_REPORT_MODE {
  LAST_FIX = 'LAST_FIX',
  CURRENT_LOCATION = 'CURRENT_LOCATION',
  LAST_FIX_AND_CURRENT_LOCATION = 'LAST_FIX_AND_CURRENT_LOCATION',
}

export enum FUNCTION_KEY_MODE {
  NONE = 'NONE',
  DISABLED = 'DISABLED',
  GEO_FENCE_CURRENT_POSITION = 'GEO_FENCE_CURRENT_POSITION',
  GEO_FENCE = 'GEO_FENCE',
  SOS = 'SOS',
  LOCATION = 'LOCATION',
  MIXED = 'MIXED',
}

export enum POWER_KEY_MODE {
  NONE = 'NONE',
  NO_POWER_OFF = 'NO_POWER_OFF',
  POWER_OFF = 'POWER_OFF',
  SOS_MODE = 'SOS_MODE',
}

export enum GPS_MODE {
  NONE = 'NONE',
  ON_NEED = 'ON_NEED',
  ALWAYS_ON = 'ALWAYS_ON',
  ON_DURING_MOVEMENT = 'ON_DURING_MOVEMENT',
}

export enum REPORT_TYPE {
  REST = 'REST',
  MOTION = 'MOTION',
  TURNING = 'TURNING',
}

export enum TRACKER_MESSAGE_TRANSPORT {
  SMS = 'sms',
  TCP = 'tcp',
}

export enum TRACKER_MESSAGE_HISTORY_RECORD_STATE {
  REVOKED = 'REVOKED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  CLEARED = 'CLEARED',
}

export enum TRACKER_PARTICIPANT_PAIRING_OPTION {
  GENERATE_NEW = 'GENERATE_NEW',
  PAIR_EXISTING = 'PAIR_EXISTING',
  PAIR_AND_GENERATE = 'PAIR_AND_GENERATE',
}
export type DeviceEvent =
  | '+RESP:GTPNA'
  | '+RESP:GTPFA'
  | '+RESP:GTEPN'
  | '+RESP:GTEPF'
  | '+RESP:GTBPL'
  | '+RESP:GTBTC'
  | '+RESP:GTSTC'
  | '+RESP:GTSTT'
  | '+RESP:GTPDP'
  | '+RESP:GTPNL'
  | '+RESP:GTIGN and +RESP:GTIGF'
  | '+RESP:GTIGL';

export const TrackerSettingSchema = z.object({
  reportMode: z.nativeEnum(FIXED_REPORT_MODE).default(FIXED_REPORT_MODE.NONE),
  moveCheckInterval: z.number().nullable().default(null),
  moveSendInterval: z.number().nullable().default(null),
  corner: z.number().nullable().default(null),
  nonMoveBegin: z.number().nullable().default(null),
  adjustIntervalsDuringNonMovement: z.boolean().nullable().default(null),
  reportNonMovement: z.boolean().nullable().default(null),
  reportMovement: z.boolean().nullable().default(null),
  nonMovementModeDisabled: z.boolean().nullable().default(null),
  nonMoveCheckInterval: z.number().nullable().default(null),
  nonMoveSendInterval: z.number().nullable().default(null),
  nmdReportMode: z.string().nullable().default(null),
  enterMovementByCmd: z.boolean().nullable().default(null),
  estimatedTrackInterval: z.number().nullable().default(null),
  gpsMode: z.nativeEnum(GPS_MODE).default(GPS_MODE.NONE),
  reportBeginTime: z.string().nullable().default(null),
  reportEndTime: z.string().nullable().default(null),
  reportDistance: z.number().nullable().default(null),
  reportMileage: z.number().nullable().default(null),
  heartbeatInterval: z.number().nullable().default(null),
  heartbeatEnabled: z.boolean().nullable().default(null),
  infoReportInterval: z.number().nullable().default(null),
  infoReportEnabled: z.boolean().nullable().default(null),
  serverAckEnabled: z.boolean().nullable().default(null),
  functionKeyMode: z.nativeEnum(FUNCTION_KEY_MODE).default(FUNCTION_KEY_MODE.NONE),
  powerKeyMode: z.nativeEnum(POWER_KEY_MODE).default(POWER_KEY_MODE.NONE),
  allowedNetworkOperators: z.array(z.string()).nullable().default(null),
  blockedNetworkOperators: z.array(z.string()).nullable().default(null),
  activatedDeviceEvents: z.array(z.string()).nullable().default(null),
});

export type TrackerSetting = z.infer<typeof TrackerSettingSchema>;

export const TrackerStateSchema = z.object({
  // optional because of archived state
  lastConnectedAt: DateSchema.optional(),
  lastDisconnectedAt: DateSchema.optional(),
  lastDataAt: DateSchema.optional(),
  movement: z.boolean().optional(),
  battery: z.number().optional(),
  batteryVoltage: z.number().optional(),
  charging: z.boolean().optional(),
  gpsActiv: z.boolean().optional(),
  temperature: z.number().optional(),
  gpsFix: z.boolean().optional(),
  registered: z.boolean().optional(),
  signalStrength: z.number().optional(),
  isDataPossible: z.boolean().optional(),
  heartbeatLastAt: z.date().optional(),
  infoReportLastAt: z.date().optional(),
});
export type TrackerState = z.infer<typeof TrackerStateSchema>;

export const TrackerActivationSchema = z.object({
  type: z.nativeEnum(TrackerActivationType),
  paidUntil: DateSchema.nullish(),
});

export type TrackerActivation = z.infer<typeof TrackerActivationSchema>;

export const TrackerSatellitesSchema = z.object({
  satelliteNumber: z.number().default(-1),
  satelliteList: z
    .array(z.object({ satelliteId: z.number(), satellitePower: z.number() }))
    .default([]),
});

export type TrackerSatellites = z.infer<typeof TrackerSatellitesSchema>;

export const BorrowerSchema = z.object({
  id: ObjectIdSchema,
  startTime: DateSchema,
  endTime: DateSchema,
});
export type Borrower = z.infer<typeof BorrowerSchema>;

export const LastGeoSchema = z.object({
  // optional because of archived state
  lng: z.number().optional(),
  lat: z.number().optional(),
  lastTime: DateSchema.optional(),
  elv: z.number().optional(),
  accuracy: z.number().optional(),
  speed: z.number().optional(),
  azimuth: z.number().optional(),
  reportType: z.nativeEnum(REPORT_TYPE).nullable().default(null).optional(),
});
export type LastGeo = z.infer<typeof LastGeoSchema>;

export const TrackerPrototypeSchema = z.object({
  id: ObjectIdSchema.optional(),
  trackerId: z.string(),
  trackerType: z.nativeEnum(TRACKER_TYPES),
  trackerName: z.string(),
  iccid: z.string().optional(),
});
export type TrackerPrototype = z.infer<typeof TrackerPrototypeSchema>;

export const TrackerMetaSchema = z.object({
  firmwareTag: z.string().optional(),
  firmwareHash: z.string().optional(),
  firmwareBranch: z.string().optional(),
  firmwareBuildDate: DateSchema.optional(),
  firmwareVersion: z.string().optional(),
  hardwareVersion: z.string().optional(),
  hardwareModem: z.string().optional(),
  hardwareModemSW: z.string().optional(),
  bootloaderVersion: z.string().optional(),
  simId: z.string().optional(),
  iccid: z.string().optional(),
});
export type TrackerMeta = z.infer<typeof TrackerMetaSchema>;

export const TrackerLogisticsSchema = z.nativeEnum(LogisticsState);
export type TrackerLogistics = z.infer<typeof TrackerLogisticsSchema>;

export const TrackerNetworkSchema = z.object({
  radioType: z.nativeEnum(RadioType).optional(),
  mcc: z.string().optional(),
  mnc: z.string().optional(),
  cellId: z.string().optional(),
  locationAreaCode: z.string().optional(),
  name: z.string().optional(),
  country: z.string().optional(),
  countryCode: z.string().optional(),
  cellLocation: z
    .object({
      accuracy: z.number().optional(),
      lat: z.number().optional(),
      lng: z.number().optional(),
    })
    .optional(),
  updatedAt: DateSchema.optional(),
});
export type TrackerNetwork = z.infer<typeof TrackerNetworkSchema>;

export const TrackerSchema = TrackerPrototypeSchema.extend({
  id: ObjectIdSchema,
  state: TrackerStateSchema,
  lastGeo: LastGeoSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  activation: TrackerActivationSchema,
  editors: z.array(ObjectIdSchema),
  owner: ObjectIdSchema,
  borrowers: z.array(BorrowerSchema),
  meta: TrackerMetaSchema.optional(),
  // optional because of archived state
  logistics: TrackerLogisticsSchema.optional(),
  setting: TrackerSettingSchema.optional(),
  satellites: TrackerSatellitesSchema.optional(),
  network: TrackerNetworkSchema.optional(),
  rfidUid: z.string().nullable().optional(),
  password: z.string().optional(),
  notes: z.string(),
});
export type Tracker = z.infer<typeof TrackerSchema>;

export const PublicTracker = TrackerPrototypeSchema.pick({ trackerName: true }).extend({
  id: ObjectIdSchema,
  activation: TrackerActivationSchema,
  state: TrackerStateSchema,
  lastGeo: LastGeoSchema.pick({ lat: true, lng: true, elv: true, lastTime: true }),
  updatedAt: DateSchema,
});
export type PublicTracker = z.infer<typeof PublicTracker>;

export const TrackerMessageHistoryRecordSchema = z.object({
  id: ObjectIdSchema,
  statusCode: z.nativeEnum(TRACKER_MESSAGE_HISTORY_RECORD_STATE),
  creatorId: ObjectIdSchema.nullable(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
  messageId: ObjectIdSchema,
  statusMessage: z.string().nullable(),
  payload: BufferSchema.optional(),
});

export type TrackerMessageHistoryRecord = z.infer<typeof TrackerMessageHistoryRecordSchema>;

export const TrackerMessageSchema = z.object({
  id: ObjectIdSchema,
  payload: BufferSchema,
  templateId: ObjectIdSchema.nullable(),
  parameters: z.record(z.string()).optional(),
  label: z.string(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
  plannedAt: DateSchema,
  closeAfter: DateSchema,
  maxNumOfAttempts: z.number(),
  trackerId: ObjectIdSchema,
  creatorId: ObjectIdSchema,
  responseId: z.string().nullable(),
  transport: z.nativeEnum(TRACKER_MESSAGE_TRANSPORT).default(TRACKER_MESSAGE_TRANSPORT.TCP),
  historyRecords: z.array(TrackerMessageHistoryRecordSchema).default([]),
});
export type TrackerMessage = z.infer<typeof TrackerMessageSchema>;

export const TrackerMessagesSchema = z.array(TrackerMessageSchema);
export type TrackerMessages = z.infer<typeof TrackerMessagesSchema>;

export const TrackerMessageOverviewSchema = TrackerMessageSchema.omit({ payload: true });
export type TrackerMessageOverview = z.infer<typeof TrackerMessageOverviewSchema>;

export const TrackerMessagePrototypeSchema = TrackerMessageSchema.partial().required({
  payload: true,
  trackerId: true,
  plannedAt: true,
});
export type TrackerMessagePrototype = z.infer<typeof TrackerMessagePrototypeSchema>;

export const TrackerMessageTemplateBasedPrototypeSchema = TrackerMessagePrototypeSchema.partial()
  .required({
    templateId: true,
    parameters: true,
  })
  .omit({
    payload: true,
    responseId: true,
    trackerId: true,
    creatorId: true,
    createdAt: true,
    updatedAt: true,
    id: true,
  });
export type TrackerMessageTemplateBasedPrototype = z.infer<
  typeof TrackerMessageTemplateBasedPrototypeSchema
>;

export const TrackerMessageHistoryRecordPrototypeSchema =
  TrackerMessageHistoryRecordSchema.partial().required({
    statusCode: true,
    messageId: true,
  });
export type TrackerMessageHistoryPrototype = z.infer<
  typeof TrackerMessageHistoryRecordPrototypeSchema
>;

export const SimCardOverviewSchema = z.object({
  id: ObjectIdSchema,
  iccid: z.string(),
  userId: ObjectIdSchema.nullable(),
  status: SimCardSchema.shape.status,
});

export type SimCardOverview = z.infer<typeof SimCardOverviewSchema>;

export const TrackerOverviewSchema = TrackerSchema.extend({
  messages: z.array(TrackerMessageOverviewSchema),
  eventIds: z.array(ObjectIdSchema),
  simCard: SimCardOverviewSchema.nullable(),
});

export type TrackerOverview = z.infer<typeof TrackerOverviewSchema>;
